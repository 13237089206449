.NewSiteForm__nav {
    flex: 0 0 25%;
    padding: 1em;
    background: white;
    box-shadow: 3px 3px 3px #ddd;
    z-index: 1;
    font-weight: 600;
    font-size: 12px;
    width: 25%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;

}

.formNav__section {
    margin: 5px 0;
    cursor: pointer;
}

.formNav__section:hover {
    background: rgba(247, 145, 29, 0.6);
    outline: 1px solid rgba(247, 145, 29, 1);
    color: white;
}

.tagline {
    margin: 1em 0;
    text-align: center;
    font-weight: 600;
    color: #f7911d;
    font-size: 1.1em;
}

.header__logo {
    display: block;
    max-width: 150px;
    margin: 1em auto;
}

.formNav__title a {
    display: block;
    text-decoration: none;
    color: inherit;
    padding: 5px;
}

.searchInputContainer{
    width: 90%;
    display: flex;
    flex-direction: column;
    padding: .5rem;
    margin: 0 auto;
    margin-bottom: 20px;
}