body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}


.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transition: 0.3s ease-in;
}

.arrow.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.arrow.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}
