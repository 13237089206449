.formSection {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    margin-bottom: 1em;
}

.section__title {
    font-size: 2rem;
    font-weight: 600;
    flex: 1 1 100%;
    margin-bottom: 1em;
}

.section__desc {
    flex: 0 0 25%;
    margin-bottom: 1em;
    font-weight: 600;
}

.section__content {
    flex: 0 0 70%;
    background: white;
    padding: 1em;
}


@media (max-width: 960px) {
    .section__desc {
        flex: 1 1 100%;
    }
}
