.inputItem {
    display: flex;
    flex-flow: column;
    margin: 10px 0;
}

.inputItemWrap {
    margin: 10px 0;
}


.inputErrorMessage {
    margin-top: -12px;
    font-size: 12px;
    padding: 0 2px;
    color: red;
}

.inputItem label {
    margin-bottom: 5px;
    font-size: 1.1em;
    font-weight: 600;
}

.inputItem input, select {
    height: 30px;
}

.inputItem__checkbox,
.inputItem__color {
    display: flex;
    flex-flow: row;
    align-items: center;
}

.inputItem__color label {
    flex: 0 0 45%;
}

.inputItem__checkbox label {
    margin: 0;
    margin-left: 5px;
    order: 1;
}

.inputItem label span.infoIconContainer{
    cursor: pointer;
    font-size: 16px;
}

.loaderContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 200px;
}

.loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.loader div {
    position: absolute;
    border: 4px solid orange;
    opacity: 1;
    border-radius: 50%;
    animation: loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loader div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes loader {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0;
        left: 0;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}
