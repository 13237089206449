.NewSiteForm {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-right: auto;
    margin-left: auto;
    background: #f6f8fb;
    min-height: 100vh;
}

.NewSiteForm__main {
    display: flex;
    flex-flow: nowrap;
    flex-direction: row;
}

.NewSiteForm form {
    flex: 0 0 70%;
    padding: 1em 2em;
    background: #f6f8fb;
    width: 70%;
    margin-left: auto;
}

.NewSiteForm .accordion__title {
    background: #f7911d;
    color: white;
    padding: 1em;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-weight: 800;
}

.NewSiteForm .arrow {
    border-color: white;
}

.NewSiteForm__main.login-screen .NewSiteForm__nav {
    display: none;
}

.NewSiteForm__main.login-screen form {
    flex: 1 1 100%;
    height: 100%;
}

textarea {
    height: 200px;
}

#contactInfoPrimaryHours .inputItemWrap,
#contactInfoSecondaryHours .inputItemWrap,
#contactInfoTertiaryHours .inputItemWrap,
#contactInfoFourthHours .inputItemWrap {
    display: inline-block;
    width: 45%;
    padding: 0 1em;
}

.loginScreenHeader {
    text-align: center;
}

.logged-in .loginScreenHeader {
    display: none;
}


@media (max-width: 960px) {
    .NewSiteForm form {
        padding: 1em;
    }
}
